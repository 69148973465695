/* activity page css */

.timeEdit_activity .ant-picker {
  padding: 0px 0px !important;
}

.timeEdit_activity .ant-picker .ant-picker-suffix {
  display: none !important;
}

.timeEdit_activity .ant-picker .ant-picker-clear {
  display: none !important;
}

/* dahsbord css */
.ant-progress .ant-progress-text {
  display: block !important;
  width: 100% !important;
  text-align: end !important;
  margin-right: 5px !important;
  color: white;
  margin-inline-start: 0 !important;
}

.ant-progress.ant-progress-show-info .ant-progress-outer {
  padding-inline-end: 0px !important;
}

/* otp screen  */

.otp-inputs div input {
  width: 3rem !important;
  margin: 8px;
  padding: 13px !important;
}

.focusstyle_class {
  border: 1px solid red !important;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #F5F5F5;
  -webkit-text-fill-color: #828282;
  -webkit-box-shadow: 0 0 0px 1000px #F5F5F5 inset;
  box-shadow: 0 0 0px 1000px #F5F5F5 inset;
  transition: background-color 5000s ease-in-out 0s;
  color: #828282;
}
