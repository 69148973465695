.ant-drawer {
  outline: none;
}
.ant-drawer-body {
  padding: 16px !important;
}

.datepicker_activity .ant-picker .ant-picker-input > input {
  width: 0 !important;
  padding: 0 !important;
}

.datepicker_activity .ant-picker {
  padding: 2px 7px 2px 2px !important;
  border-radius: 50%;
}

/* Inputs  */
label {
  color: #828282;
}

/* Set the text color for all input elements */
input {
  color: #000;
  border-radius: 8px !important;
  padding: 6px 8px !important; /* Change this to your preferred text color */
  outline: none;
}

/* Set the placeholder text color for all input elements */
input::placeholder {
  color: #828282; /* Change this to your preferred placeholder color */
}

select {
  outline: none;
}

button {
  font-size: 12px !important;
  font-weight: 600;
}

/* custom sscrollar */

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #92b0ae transparent;
  padding: 0 5px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #e9efef;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #92b0ae;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #3a706d;
}

.ant-popconfirm-buttons button {
  background-color: none !important;
  padding: 0px !important;
  border: none !important;
  box-shadow: none !important;
  &:hover {
    background-color: #3a706d !important;
    color: white !important;
    padding: 0px !important;

    /* Add any other hover styles you want to apply */
  }
}

.ant-popconfirm-buttons button .sure_btn {
  background-color: #3a706d !important;
  border-radius: 4px;
}
/* confirmation popup */
.confirmpopUp-custom-button-style {
  padding: 0 8px;
  /* background-color: #; Example background color */
  /* color: #; Example text color */
  /* Add any other styles you want to apply */

  /* Override Ant Design's hover styles */
}

.ant-modal-root .ant-modal-wrap .ant-modal {
  top: 200px;
}
.dekstop-view {
  display: block;
}

.mobile-view {
  display: block; /* Display the mobile view component */
}

@media (min-width: 771px) {
  .mobile-view {
    display: none; /* Hide the mobile view component when the screen width is 771px or larger */
  }
}
@media (max-width: 771px) {
  .dekstop-view {
    display: none; /* Hide the mobile view component when the screen width is 771px or larger */
  }
}

.ant-modal {
  top: 26% !important;
}

/* loader in profile */

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.475), rgba(0, 0, 0, 0.532));
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #114442;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.routinePopup {
  top: 40% !important;
}

.routinePopup .ant-modal-content {
  padding: 5px 16px !important;
}

.apexcharts-legend-text {
  font-weight: 600 !important;
}

/* .apexcharts-legend .apexcharts-legend-series:nth-child(2) {
  display: none;
} */

.font-montserrat {
  font-family: "Montserrat", sans-serif; /* Replace 'Montserrat' with your desired font-family */
}

.custom-popconfirm .ant-popover-content {
  font-family: "Montserrat", sans-serif; /* Apply the font-family to Popconfirm content */
}

.footerCount_14ttrqn {
  display: none;
}

.backArrow_nest_page {
  font-size: 20px;
  width: 33px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: red 0.3s, box-shadow 0.3s;
  border-radius: 50%;
}

.backArrow_nest_page:hover {
  background-color: #e5e5e5; /* Change to your desired hover background color */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); /* Change to your desired box shadow */
}

.backArrow_nest_page:active {
  background-color: #d1d1d1; /* Change to your desired active background color */
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5); /* Change to your desired active box shadow */
}
