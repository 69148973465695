/* Media query for mobile devices */
@media (max-width: 767px) {
  input::placeholder {
    font-size: 11px;
  }
  .formik-error {
    font-size: 10px;
  }
  label {
    font-size: 12px;
  }
  /* activity page image */
}

/* Media query for tablets and small laptops */
@media (min-width: 768px) and (max-width: 1023px) {
  input::placeholder {
    font-size: 13px; /* Adjust the font size for tablets and small laptops */
  }
  .formik-error {
    font-size: 11px;
  }
  label {
    font-size: 14px;
  }
}

/* Media query for larger laptops and desktops */
@media (min-width: 1024px) {
  input::placeholder {
    font-size: 14px; /* Adjust the font size for larger laptops and desktops */
  }
  .formik-error {
    font-size: 11px;
  }
  label {
    font-size: 15px;
  }
}

@media (max-width: 400px) {
  .activity_image {
    width: 40px;
    height: 40px;
  }

  .btn-activity {
    flex-direction: column !important;
  }
}
