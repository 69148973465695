@tailwind base;
@tailwind components;
@tailwind utilities;

/* monssertat font  */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* poppins font  */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.font-poppins {
  font-family: "Poppins", sans-serif !important;
}

.font-montserrat {
  font-family: "Montserrat", sans-serif;
}
body {
  font-family: "Montserrat", sans-serif;
}

.sidebar-panel_list ul li .active div {
  color: #fff;
  background: #000;
}

.ant-drawer {
  outline: none;
}
.ant-drawer-body {
  padding: 16px !important;
}

input {
  background-color: #f5f5f5;
}

/* Set the text color for all input elements */
input {
  color: #000;
  border-radius: 8px !important;
  padding: 6px 8px !important; /* Change this to your preferred text color */
}
input#password,
input#confirmPassword {
  padding-right: 30px !important;
}

/* Set the placeholder text color for all input elements */
input::placeholder,
select::placeholder,
textarea::placeholder {
  color: #828282; /* Change this to your preferred placeholder color */
}

.datepicker_activity .ant-picker .ant-picker-input > input {
  width: 0 !important;
  padding: 0 !important;
}

.datepicker_activity .ant-picker {
  padding: 2px 7px 2px 2px !important;
  border-radius: 50%;
}

.ant-notification-notice-wrapper
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
  padding-right: 20px;
}

.ant-notification .ant-notification-notice-wrapper .ant-notification-notice {
  padding: 10px 12px !important;
}
.ant-notification
  .ant-notification-notice-wrapper
  .ant-notification-notice-close {
  width: 0px !important;
}

.time_picker_none .ant-picker-clear {
  display: none !important;
}
